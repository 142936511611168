import React from 'react';
import { 
  Icon,
  SkeletonLoader } from './components'
import { 
  Home,
  Give
} from './views';

export const routesConfig = [
  {
    path: "/give",
    element: Give
  },
  {
    path: "/",
    element: Home
  }
];
