import React, { useState, useEffect } from 'react';

import { useTooltip } from '../../../hooks'
import { Photo, Radio, TextareaAutosize } from '../../../components'
import { renderText } from '../helpers'



export const Question = ({ question, formData, onChange, props, disabled, readOnly, questionnaireResponses, number, showNumbers }) => {

  const { showTooltip, hideTooltip } = useTooltip()

  const renderLikert = (questionId, data, value, n) => {
    
    const large = true
    let alternatives = []

    for (let i = 0; i<n; i++) {
      alternatives.push(
        <div className="alternative" key={i}>
          <Radio 
            name={questionId} 
            value={i+1} 
            checked={value === (i+1).toString()} 
            onChange={onChange} 
            label={i+1} 
            className={ large ? "label-center" : "" }
            disabled={disabled}
            readOnly={readOnly} 
          />
        </div>
      )
    }

    if (large) {
      return (
        <div className="likert large">
        <div className="likert-labels">
          { data.question_min_label && <div className="likert-min-label">{data.question_min_label}</div>}
          { data.question_max_label && <div className="likert-max-label">{data.question_max_label}</div>}
        </div>
        <div className="alternatives">{ alternatives }</div>
        </div>
      )
    } else {
      return (
        <div className="likert">
        { data.question_min_label && <div className="likert-min-label">{data.question_min_label}</div>}
        <div className="alternatives">{ alternatives }</div>
        { data.question_max_label && <div className="likert-max-label">{data.question_max_label}</div>}
        </div>
      )
    }

  }

  const renderQuestion = (data, formData) => {
    let questionId = data.question_id
    let response

    if (!formData) return null


    const value = Array.isArray(formData) && formData.length > 0 ? Object.values(formData[0])[0] : formData[questionId]

    switch (data.question_type_code) {
      case "text":
        response = <TextareaAutosize name={questionId} value={value} onChange={onChange} disabled={disabled} readOnly={readOnly} minRows={5} />
        break
      case "likert3":
        response = renderLikert(questionId, data, value, 3)
        break
      case "likert5":
        response = renderLikert(questionId, data, value, 5)
        break
      case "likert7":
        response = renderLikert(questionId, data, value, 7)
        break
      case "likert9":
        response = renderLikert(questionId, data, value, 9)
        break
      case "section":
        response = null
        break
      default:
      break
    }

    return <div className="question-body">{response}</div>

  }

  const renderTitle = (data, props, isMandatory, questionType) => {
    if (!data) return null

    let response

    switch (questionType) {
      case "section":
        response = <div className="question-title">{ renderText(data, props) }</div>
        break
      default:
        response = <div className="question-title">{ (showNumbers ? `${number}. ` : "") + renderText(data, props) + (isMandatory && !readOnly ? " *" : "") }</div>
        break
    }

    return response
  }

  const renderDescription = (data, props) => {
    if (!data) return null
    return <div className="question-description">{ renderText(data, props) }</div>
  }

  return question && (
    <div className={"Question " + question.question_type_code}>

      { renderTitle(question.question_title, props, question.question_is_mandatory, question.question_type_code) }

      { renderDescription(question.question_description, props) }

      { renderQuestion(question, formData) }
      
    </div>
  )
};


