import React, { useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as Spinner} from '../assets/img/logo_loading.svg';
import { FullscreenMessage } from '../components'
import { useGlobalState, useMessage } from '../hooks'

export const LoadApp = ({login}) => {

  const [invite, setInvite] = useGlobalState('invite')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')

  const { message, showMessage, hideMessage } = useMessage()

  const navigate = useNavigate()
  const location = useLocation()
  const { token } = useParams()

  useEffect(() => {
    
    // Redirect to start page if user is already logged in
    if (isLoggedIn && invite) { 
      navigate('/')
    }    

    if (token) {
      requestLogin(token)
    }

  }, [token]);

  const requestLogin = async (inviteToken) => {
    try {

      const state = location.state || { from: { pathname: "/" } };

      await login({invite_token: inviteToken, state})

    } catch (error) {
      showMessage(error, 'error', true)
      navigate("/login/" + token)
    }
  }

  return (
    <div className="LoadApp fullscreen">
      <div className="fullscreen-content">

        <Spinner className="fullscreen-loading-logo" />

        {message && message.type === "error"
          ? <FullscreenMessage message={message} />
          : <div className="fullscreen-loading-text">Loading Apps...</div>
        }
      </div>
    </div>
  )
}