import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Route, Routes, Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { inviteService } from '../services'
import { PrivateRoute } from '../middleware'
import { 
  Icon, 
  Photo, 
  Tooltip,
  Message
} from '../components'
import { routesConfig } from '../routesConfig'
import { Login, LoadApp, NotFound } from './'
import { useGlobalState, useMessage } from '../hooks';

export const App = () => {
  return (
    <React.Fragment>
      <_App />
      <Tooltip />
      <Message />
    </React.Fragment>
  )
}

const _App = () => {

  const [loading, setLoading] = useState(true)
  const [invite, setInvite] = useGlobalState('invite')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')
  const [csrfToken, setCsrfToken] = useGlobalState('csrfToken')

  const { hideMessage } = useMessage()

  const { token } = useParams()

  const location = useLocation()
  const navigate = useNavigate()

  useEffect( () => {
    // Initialize app
    initialize()

  }, [])

  const initialize = async () => {

    try {

      if (location.pathname === "/" || "/give") {
        await Promise.all([
          // Get login state
          await login({})
        ])
        .catch(e => { throw e })
      }

      setLoading(false)

    } catch (err) {
      setLoading(false)
    }
  }

  const login = async ({invite_token, state}) => {
    try {

      const loginState = await inviteService.getByToken(invite_token)

      if (loginState.invite) {
        setInvite(loginState.invite)
      } 

      if (loginState.csrf) {
        setCsrfToken(loginState.csrf)
      } 


      if (loginState.invite.latest_invite_event_type_code === 'responded') {
        navigate(`/give?sent=true`)
      } 

      if (loginState.loginHandled && loginState.invite.latest_invite_event_type_code !== 'responded') {

        state = state || loginState.state

        // Restore url
        navigate({...state.from, ...state})
      }

      setIsLoggedIn(loginState.isLoggedIn)
      hideMessage()


    } catch (e) {
      throw e  
    }
  }

  const renderRoutes = () => {
    return routesConfig.map(({ path, name, element, roles, routes }, key) => {
      if (routes) {
        return (
          <Route 
            key={path} 
            path={path} 
            element={
              <PrivateRoute 
                roles={ roles }
                element={ element }   
              />
            }
          >
            { renderRoutes(routes) }
          </Route>
        )
      } else {
        return (
          <Route 
            key={path} 
            path={path} 
            element={
              <PrivateRoute 
                roles={ roles }
                element={ element }   
              />
            }
          />
        )
      }
    })
  }

  return (

    <div className={'App'}>
      
      <div className="main-container">
        { isLoggedIn && 
          !(location.pathname === "/login") && 
          !loading &&

        <div className="main-content-header-wrap">
          <div className={"main-content-header" + (location.pathname === "/" ? " home" : "")} >
            
            <div className="Breadcrumbs">
              <Link to="/"><span className="breadcrumb-item-container">
                <Icon className="breadcrumb-item" name='kayenta' tooltip='Home' />
              </span></Link>
            </div>

            <div className="menu">

            </div>
          </div>
        </div>
        }

        <div className="main-content">
          { loading 
            ? <LoadApp />
            : 
              <Routes>

                <Route path="/login/:token" element={
                  <Login
                    login={login} />
                }/>

                <Route path="/login" element={
                  <Login 
                    login={login} />
                }/>

                {renderRoutes()}

                <Route path="/:token" element={
                  <LoadApp
                    login={login} />
                }/>

                <Route path='*' element={<NotFound />}/>

              </Routes>
              
          }
        </div>
      </div>  
    </div>

  )
}