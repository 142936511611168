import React, { useState, useEffect, useLayoutEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Checkbox, formatSelectOptions, Input, Select, Button } from '../components';
import { formatData } from '../helpers'
import { useGlobalState, useDebounce, useMessage } from '../hooks'
import { Questionnaire, IconFeedback } from '../components'
import { inviteService, questionService, questionnaireService } from '../services'


const _GiveFeedbackForm = ({ submitForm }, ref) => { 
	
	const [loading, setLoading] = useState(true)
	const [sending, setSending] = useState(false)
	const [invite, setInvite] = useGlobalState('invite');
	const [formData, setFormData] = useState({});
	const [questionnaire, setQuestionnaire] = useState()
  const [questions, setQuestions] = useState([])
	const [searchTerm, setSearchTerm] = useState('');

  const { showMessage } = useMessage()
  const navigate = useNavigate()

	useEffect( () => {
		// Get items
		fetchItems()
	}, []);

	useLayoutEffect( () => {
  	// Get form data from local storage
    setFormData(JSON.parse(localStorage.getItem(invite.invite_id + '-formData')) || {})
  }, [])

	useEffect( () => {
		// Get items
		if (invite) {
			setFormData(prev => ({
					...prev,
	      	user_id: invite.user_id,
	      	invite_id: invite.invite_id,
	      	questionnaire_id: invite.questionnaire_id,
	      	respondent_is_internal: invite.respondent_is_internal
	      })
			)
		}

	}, [invite]);

  const fetchItems = async () => {
    setLoading(true)

    const promises = [
      fetchQuestionnaire(), 
      fetchQuestions()
    ]

    await Promise.all(promises).then(data => {
      setQuestionnaire(data[0])
      setQuestions(data[1])
    })
    .catch(err => showMessage(err, 'error'))

    setLoading(false)
  }

  const fetchQuestionnaire = () => {
    return new Promise( async (resolve, reject) => {
      try {

        const res = await questionnaireService.getById(invite.questionnaire_id)
        resolve(res.questionnaires[0])

      } catch (err) {
        reject(err)
      };
    })
  }

  const fetchQuestions = () => {
    return new Promise( async (resolve, reject) => {
      try {
        const res = await questionService.getByQuestionnaireId(invite.questionnaire_id)
        resolve(res.questions)

      } catch (err) {
        reject(err)
      };
    })
  }
  
	const handleInputChange = async (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  let data
	  if (name === 'user_id') {
	  	data = { ...formData, [name]: value, respondent_is_internal: true }
	  } else {
	  	data = { ...formData, [name]: value }
	  }

	  setFormData(data)

	  // Persist state in local storage
    localStorage.setItem(invite.invite_id + '-formData', JSON.stringify(data))
	}

	const onSubmit = event => {

		event.preventDefault()

		setSending(true)

		let response = {
			questionnaire_response: {
				user_id: formData.user_id,
				questionnaire_id: formData.questionnaire_id,
				respondent_is_internal: formData.respondent_is_internal,
				invite_id: formData.invite_id
			},
			responses: Object.entries(formData).filter(([key, value]) => !isNaN(key)).map( ([key, value]) => { 
				return {
					question_id: key,
					response_value: value
				}
			})
		}

		if (invite.respondent_is_internal) {
			response.questionnaire_response.respondent_user_id = invite.respondent_user_id
		} else {
			response.respondent = {
				respondent_name: invite.respondent_name,
				respondent_email: invite.respondent_email
			}
		}

		// submit form
		submitForm(response)
		.then(res => {
			localStorage.removeItem(invite.invite_id + '-formData');
		})
		.catch(err => {
		})
		.finally( res => {
			setSending(false)
		})
		    
	}

  const formatQuestionnaireProps = () => {
  	return {
  		user_fullname: invite.user_fullname,
  		user_first_name: invite.user_fullname.split(' ')[0]
  	}
  }

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			  ref={ref}
			>

	    { ((formData.user_id && questionnaire && questions) || loading) && 
				<Questionnaire 
					questionnaire={questionnaire}
					questions={questions}
					formData={formData}
					onChange={handleInputChange}
					onSubmit={onSubmit}
					props={formatQuestionnaireProps()}
					loading={loading}
					sending={sending}
				/>
			}
	    </form>
	  </div>
  )
}

export const GiveFeedbackForm = forwardRef(_GiveFeedbackForm)


