import config from '../config';
import { authHeader, customFetch, handleResponse, commonRequestOptions } from '../helpers';

const endpointPath=`feedback/question/public`

export const questionService = {
    getByQuestionnaireId
};

function getByQuestionnaireId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/questionnaire/${id}`, requestOptions, signal)
    .then(handleResponse)
}