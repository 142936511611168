import config from '../config';
import { authHeader, customFetch, handleResponse, commonRequestOptions } from '../helpers';

const endpointPath=`feedback/questionnaire/public`

export const questionnaireService = {
    getById
};

function getById(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/${id}`, requestOptions, signal)
    .then(handleResponse)
}
