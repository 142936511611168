import config from '../config';
import { customFetch, authHeader, jsonHeader, handleResponse, commonRequestOptions, subjects } from '../helpers';

const endpointPath=`feedback/invite/public`

export const inviteService = {
    getByToken,
    logout
};

function getByToken(invite_token, state, signal) {
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ invite_token, state }),
        ...commonRequestOptions()
    };

    let endpoint = `${config.api.url}/${endpointPath}/token`

    return customFetch(endpoint, requestOptions, signal).then(handleResponse)
}

function logout(signal) {
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        ...commonRequestOptions()
    };

    let endpoint = `${config.api.url}/${endpointPath}/logout`

    return customFetch(endpoint, requestOptions, signal).then(handleResponse)
}
