import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMessage } from '../hooks';
import { Button } from '../components';
import { IconFeedback } from '../components'
import { GiveFeedbackForm } from '../forms';
import { questionnaireResponseService, inviteService } from '../services'

export const Give = () => {

  const [searchParams] = useSearchParams()
  const isSent = Object.fromEntries(searchParams).sent === "true" ? true : false

  const [sent, setSent] = useState(isSent)

  const { showMessage } = useMessage()
  const navigate = useNavigate()  

  const close = () => {
    navigate("/login")
  }

  useEffect( () => {
    if (isSent && sent) {
      logout()
    }
  }, [sent])

  const logout = async () => {
    try {
      await inviteService.logout()
        .catch(e => { throw e})
    } catch (err) {
      showMessage(JSON.stringify(err), 'error')
    }
  }

  const addFeedback = async (data) => {
    
    await questionnaireResponseService.create(data)
      .then( res => {
        setSent(true)
      })
      .catch(err => {
        showMessage(JSON.stringify(err), 'error')
        throw err       
      });
  }

  return (
    <div className="Give columns">
      <div className="center-column column">
        
        { sent ?  
          <div className="questionnaire-sent">
            <div className="questionnaire-sent-icon"><IconFeedback name="give_feedback"/></div>
            <div className="questionnaire-sent-text">Thanks!</div>
            <div className="questionnaire-sent-smalltext">Your feedback has been sent</div>
            <Button value="Close" className="button" onClick={close} />
          </div>
      
        :  <GiveFeedbackForm submitForm={addFeedback} />
        }

      </div>
    </div>
  );
}