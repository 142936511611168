import React from 'react';
import './IconFeedback.css';
import { useTooltip } from '../../hooks'

export const IconFeedback = ({name, value, values, tooltip="", onClick }) => {

  const {showTooltip, hideTooltip} = useTooltip()

  if (!name) return null
    
	name = name.replace(/ /g, '-').toLowerCase()

	const icons = {
    analyze_feedback: analyzeFeedback(),
		invite_feedback: inviteFeedback(),
		give_feedback: giveFeedback(),
    not_found: notFound()
	}

	const icon = icons[name]

	if (icon !== undefined && onClick) {
		return <div onClick={onClick}>{icon}</div>
  } else if (icon !== undefined) {
    return icon
	} else {
	  return (
	      <div 
          className={ "icon icon-" + name } 
          onMouseEnter={ () => tooltip && showTooltip({title: tooltip})} 
          onMouseLeave={ () => tooltip && hideTooltip() }
          onClick={onClick}
        >
        </div>   
	  )
	}
};

const analyzeFeedback = () => {
  return (
    <svg className="icon icon-analyze-feedback"
         width="100%" 
         height="100%" 
         viewBox="0 0 24 30" 
         preserveAspectRatio="xMidYMid meet"
         style={{fillRule:"evenodd", clipRule:"evenodd", strokeLinecap:"round", strokeLinejoin:"round", strokeMiterlimit:1}}>  
      
      <path d="M14.616,19.031l1.084,1.083c-0.023,0.411 0.123,0.83 0.436,1.144c0,-0 5.304,5.303 5.304,5.303c0.585,0.585 1.535,0.585 2.121,-0c0.585,-0.586 0.585,-1.536 -0,-2.121l-5.303,-5.304c-0.314,-0.313 -0.733,-0.459 -1.144,-0.436l-1.083,-1.084c1.232,-1.54 1.969,-3.493 1.969,-5.616c0,-4.967 -4.033,-9 -9,-9c-4.967,0 -9,4.033 -9,9c-0,4.967 4.033,9 9,9c2.123,0 4.076,-0.737 5.616,-1.969Zm2.935,0.813l5.303,5.303c0.195,0.195 0.195,0.512 -0,0.707c-0.195,0.195 -0.512,0.195 -0.707,-0c-0,-0 -5.303,-5.303 -5.303,-5.303c-0.196,-0.195 -0.196,-0.512 -0,-0.707c0.195,-0.196 0.512,-0.196 0.707,-0Zm-8.551,-15.844c4.415,0 8,3.585 8,8c0,4.415 -3.585,8 -8,8c-4.415,0 -8,-3.585 -8,-8c-0,-4.415 3.585,-8 8,-8Zm-0.5,8.389c0,-0.276 -0.224,-0.5 -0.5,-0.5l-1,-0c-0.276,-0 -0.5,0.224 -0.5,0.5l-0,3.111c-0,0.276 0.224,0.5 0.5,0.5l1,0c0.276,0 0.5,-0.224 0.5,-0.5l0,-3.111Zm6,-3.889c0,-0.276 -0.224,-0.5 -0.5,-0.5l-1,0c-0.276,0 -0.5,0.224 -0.5,0.5l-0,7c-0,0.276 0.224,0.5 0.5,0.5l1,0c0.276,0 0.5,-0.224 0.5,-0.5l0,-7Zm-3,1.944c0,-0.276 -0.224,-0.5 -0.5,-0.5l-1,0c-0.276,0 -0.5,0.224 -0.5,0.5l0,5.056c0,0.276 0.224,0.5 0.5,0.5l1,0c0.276,0 0.5,-0.224 0.5,-0.5l0,-5.056Zm-6,1.167c0,-0.276 -0.224,-0.5 -0.5,-0.5l-1,0c-0.276,0 -0.5,0.224 -0.5,0.5l0,3.889c0,0.276 0.224,0.5 0.5,0.5l1,0c0.276,0 0.5,-0.224 0.5,-0.5l0,-3.889Z" 
        style={{fill: "#222020"}}/>
      
      <path className="fill" d="M14.616,19.031l1.084,1.083c-0.023,0.411 0.123,0.83 0.436,1.144c0,-0 5.304,5.303 5.304,5.303c0.585,0.585 1.535,0.585 2.121,-0c0.585,-0.586 0.585,-1.536 -0,-2.121l-5.303,-5.304c-0.314,-0.313 -0.733,-0.459 -1.144,-0.436l-1.083,-1.084c1.232,-1.54 1.969,-3.493 1.969,-5.616c0,-4.967 -4.033,-9 -9,-9c-4.967,0 -9,4.033 -9,9c-0,4.967 4.033,9 9,9c2.123,0 4.076,-0.737 5.616,-1.969Zm-6.116,-6.642c0,-0.276 -0.224,-0.5 -0.5,-0.5l-1,-0c-0.276,-0 -0.5,0.224 -0.5,0.5l-0,3.111c-0,0.276 0.224,0.5 0.5,0.5l1,0c0.276,0 0.5,-0.224 0.5,-0.5l0,-3.111Zm6,-3.889c0,-0.276 -0.224,-0.5 -0.5,-0.5l-1,0c-0.276,0 -0.5,0.224 -0.5,0.5l-0,7c-0,0.276 0.224,0.5 0.5,0.5l1,0c0.276,0 0.5,-0.224 0.5,-0.5l0,-7Zm-3,1.944c0,-0.276 -0.224,-0.5 -0.5,-0.5l-1,0c-0.276,0 -0.5,0.224 -0.5,0.5l0,5.056c0,0.276 0.224,0.5 0.5,0.5l1,0c0.276,0 0.5,-0.224 0.5,-0.5l0,-5.056Zm-6,1.167c0,-0.276 -0.224,-0.5 -0.5,-0.5l-1,0c-0.276,0 -0.5,0.224 -0.5,0.5l0,3.889c0,0.276 0.224,0.5 0.5,0.5l1,0c0.276,0 0.5,-0.224 0.5,-0.5l0,-3.889Z" 
        style={{fill: "#222020"}}/>

    </svg>
  )
}


const inviteFeedback = () => {
  return (
    <svg className="icon icon-invite-feedback"
         width="100%" 
         height="100%" 
         viewBox="0 0 24 30" 
         preserveAspectRatio="xMidYMid meet"
         style={{fillRule:"evenodd", clipRule:"evenodd", strokeLinecap:"round", strokeLinejoin:"round", strokeMiterlimit:1}}>

      <path d="M23.5,7l-23,4.5l6.5,3.5l16.5,-8Z" 
        style={{fill:"none", stroke:"#222020", strokeWidth:"1px"}}/>
      <path d="M10,17l8,5l5.5,-15l-13.5,10Z" 
        style={{fill:"none", stroke:"#222020", strokeWidth:"1px"}}/>
      <path d="M7.028,14.987l1.472,7.013l1.5,-5l-1.5,5l4.7,-3" 
        style={{fill:"none", stroke:"#222020", strokeWidth:"1px"}}/>

      <path className="fill" d="M23.445,6.503l-0.022,0.003l-0.008,0.001c-0.004,0.001 -0.009,0.002 -0.014,0.003l-22.997,4.499c-0.207,0.041 -0.367,0.207 -0.398,0.416c-0.032,0.209 0.071,0.415 0.257,0.515l6.33,3.409l1.418,6.754l0.028,0.089l0.015,0.033l0.039,0.065l0.05,0.06l0.066,0.056l0.089,0.051l0.073,0.026l0.075,0.014l0.073,0.003l0.076,-0.009l0.088,-0.026l0.086,-0.044l4.434,-2.83l4.532,2.833c0.128,0.08 0.285,0.098 0.427,0.049c0.143,-0.049 0.256,-0.159 0.307,-0.301l5.5,-15l0.007,-0.019l0.006,-0.022l0.006,-0.021l0.004,-0.022l0.004,-0.025c0.008,-0.061 0.004,-0.124 -0.011,-0.186l-0.009,-0.029l-0.006,-0.018l-0.008,-0.021l-0.009,-0.021l-0.002,-0.005c-0.036,-0.073 -0.088,-0.135 -0.15,-0.183l-0.021,-0.014l-0.023,-0.015l-0.006,-0.003c-0.022,-0.013 -0.045,-0.024 -0.069,-0.034l-0.025,-0.009l-0.02,-0.006c-0.061,-0.017 -0.126,-0.023 -0.192,-0.016Zm-14.079,14.351l2.898,-1.85l-1.973,-1.233l-0.925,3.083Zm9.531,-11.067l-11.3,5.479l0.989,4.708l0.935,-3.118l0,0c0.031,-0.102 0.094,-0.193 0.181,-0.258l9.195,-6.811Z" 
        style={{fill: "#222020"}}/>

    </svg>
  )
}

const giveFeedback = () => {
  return (
    <svg className="icon icon-give-feedback"
         width="100%" 
         height="100%" 
         viewBox="0 0 24 30" 
         preserveAspectRatio="xMidYMid meet"
         style={{fillRule:"evenodd", clipRule:"evenodd", strokeLinecap:"round", strokeLinejoin:"round", strokeMiterlimit:1}}>

      <path d="M8,10.5l-3.73,0c-1.07,-0 -2.083,0.485 -2.755,1.318c-0.672,0.834 -0.93,1.926 -0.703,2.973c0.39,1.794 0.848,3.898 1.19,5.471c0.347,1.598 1.762,2.738 3.397,2.738c1.692,0 3.934,0 5.544,0c1.425,-0 2.835,-0.289 4.145,-0.851c0.825,-0.354 1.609,-0.649 1.609,-0.649l1.803,-0.5l-0,-9.5l-2.5,0c-0.663,0 -1.299,-0.263 -1.768,-0.732c-0.469,-0.469 -0.732,-1.105 -0.732,-1.768c-0,-1.245 -0,-2.605 -0,-3.5c0,-0.828 -0.672,-1.5 -1.5,-1.5l-0.5,0c-0.928,-0 -1.818,0.369 -2.475,1.025c-0.656,0.656 -1.025,1.547 -1.025,2.475l-0,3Z" 
        style={{fill:"none", stroke:"#222020", strokeWidth:"1px"}}/>
      <path d="M23.5,12.25c0,-0.69 -0.56,-1.25 -1.25,-1.25l-2.5,-0c-0.69,-0 -1.25,0.56 -1.25,1.25l0,8.5c0,0.69 0.56,1.25 1.25,1.25l2.5,0c0.69,0 1.25,-0.56 1.25,-1.25l0,-8.5Z" 
        style={{fill:"none", stroke:"#222020", strokeWidth:"1px"}}/>
      
      <path className="fill" d="M11.5,3.5l0,0c-1.061,-0 -2.078,0.421 -2.828,1.172c-0.751,0.75 -1.172,1.767 -1.172,2.828l-0,2.5l-3.23,0c-1.221,-0 -2.378,0.553 -3.144,1.504c-0.767,0.952 -1.062,2.199 -0.802,3.393c0.39,1.794 0.847,3.898 1.189,5.471c0.398,1.828 2.015,3.132 3.886,3.132c1.692,0 3.934,0 5.544,0c1.493,-0 2.97,-0.303 4.342,-0.891c0.825,-0.354 1.715,-1.209 1.715,-1.609l-0,-8.9c0,-0.292 -0.116,-0.572 -0.322,-0.778c-0.206,-0.206 -0.486,-0.322 -0.778,-0.322l0.074,0.001c0.009,-0.001 0.017,-0.001 0.026,-0.001c-0.53,0 -1.039,-0.211 -1.414,-0.586c-0.375,-0.375 -0.586,-0.884 -0.586,-1.414c-0,-1.245 -0,-2.605 -0,-3.5c0,-1.105 -0.895,-2 -2,-2l-0.5,0Zm12.5,8.625c-0,-0.897 -0.728,-1.625 -1.625,-1.625c0,-0 -2.75,-0 -2.75,-0c-0.897,-0 -1.625,0.728 -1.625,1.625c-0,-0 -0,8.75 -0,8.75c-0,0.897 0.728,1.625 1.625,1.625l2.75,0c0.897,0 1.625,-0.728 1.625,-1.625c-0,0 -0,-8.75 -0,-8.75Z" 
        style={{fill: "#222020"}}/>
      
      <defs>
        <linearGradient id="icon-give-feedback-gradient-green" x1="0" y1="0" x2="100%" y2="100%" gradientUnits="userSpaceOnUse">
          <stop offset="0.4" style={{stopColor: "#9AEBCF", stopOpacity:"1"}}/>
          <stop offset="1" style={{stopColor: "#B6CFFF", stopOpacity:"1"}}/>
        </linearGradient>
      </defs>
    </svg>
  )
}

const notFound = () => {
  return (
    <svg className="icon icon-not-found"
         width="100%" 
         height="100%" 
         viewBox="0 0 24 30" 
         preserveAspectRatio="xMidYMid meet"
         style={{fillRule:"evenodd", clipRule:"evenodd", strokeLinecap:"round", strokeLinejoin:"round", strokeMiterlimit:1}}>
      
      <path d="M15.172,0c0.663,-0 1.299,0.263 1.767,0.732l6.329,6.329c0.469,0.468 0.732,1.104 0.732,1.767l0,18.672c0,0.663 -0.263,1.299 -0.732,1.768c-0.469,0.469 -1.105,0.732 -1.768,0.732l-19,0c-1.381,0 -2.5,-1.119 -2.5,-2.5l0,-25c0,-1.381 1.119,-2.5 2.5,-2.5l12.672,0Zm7.828,10c0,-0.005 0,-0.009 0,-0.014l-0,-0.94c-0,-0.301 -0.245,-0.546 -0.546,-0.546l-4.474,0c-1.369,-0 -2.48,-1.111 -2.48,-2.48l0,-4.481c0,-0.143 -0.057,-0.28 -0.158,-0.381c-0.101,-0.101 -0.238,-0.158 -0.381,-0.158l-12.461,0c-0.828,0 -1.5,0.672 -1.5,1.5l0,25c0,0.828 0.672,1.5 1.5,1.5l19,0c0.398,0 0.779,-0.158 1.061,-0.439c0.281,-0.282 0.439,-0.663 0.439,-1.061l0,-17.5Zm-7.214,4.007l-1.64,-1.64c-0.195,-0.195 -0.195,-0.512 0,-0.707c0.196,-0.195 0.512,-0.195 0.708,-0l1.639,1.64l1.64,-1.64c0.195,-0.195 0.512,-0.195 0.707,-0c0.195,0.195 0.195,0.512 0,0.707l-1.64,1.64l1.64,1.639c0.195,0.196 0.195,0.512 0,0.708c-0.195,0.195 -0.512,0.195 -0.707,-0l-1.64,-1.64l-1.639,1.64c-0.196,0.195 -0.512,0.195 -0.708,-0c-0.195,-0.196 -0.195,-0.512 0,-0.708l1.64,-1.639Zm-9.952,9.865c-0.205,0.184 -0.521,0.168 -0.706,-0.038c-0.184,-0.205 -0.168,-0.521 0.038,-0.706c1.753,-1.578 4.292,-2.378 6.834,-2.378c2.542,0 5.081,0.8 6.834,2.378c0.206,0.185 0.222,0.501 0.038,0.706c-0.185,0.206 -0.501,0.222 -0.706,0.038c-1.58,-1.422 -3.875,-2.122 -6.166,-2.122c-2.291,0 -4.586,0.7 -6.166,2.122Zm0.952,-9.865l-1.64,-1.64c-0.195,-0.195 -0.195,-0.512 0,-0.707c0.196,-0.195 0.512,-0.195 0.708,-0l1.639,1.64l1.653,-1.654c0.196,-0.195 0.512,-0.195 0.708,0c0.195,0.196 0.195,0.512 -0,0.708l-1.654,1.653l1.64,1.639c0.195,0.196 0.195,0.512 0,0.708c-0.195,0.195 -0.512,0.195 -0.707,-0l-1.64,-1.64l-1.639,1.64c-0.196,0.195 -0.512,0.195 -0.708,-0c-0.195,-0.196 -0.195,-0.512 0,-0.708l1.64,-1.639Zm9.714,-12.3l0,4.313c0,0.817 0.663,1.48 1.48,1.48l4.313,0l-5.793,-5.793Z" 
      style={{fill:'#222020'}}/>
      
      <defs>
        <linearGradient id="icon-not-found-gradient" x1="0" y1="0" x2="100%" y2="100%" gradientUnits="userSpaceOnUse">
          <stop offset="0" style={{stopColor: "#FBE9B7", stopOpacity:"1"}}/>
          <stop offset="0.4" style={{stopColor: "#FFBEAB", stopOpacity:"1"}}/>
        </linearGradient>
      </defs>
    </svg>
  )
}


