import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useMessage, useGlobalState } from '../hooks';
import { Button, Photo } from '../components';
import { IconFeedback } from '../components'
import { GiveFeedbackForm } from '../forms';
import { questionnaireResponseService } from '../services'

export const Home = () => {

  const [invite, setInvite] = useGlobalState('invite')

  const { showMessage } = useMessage()
  const [searchParams, func]  = useSearchParams()
  const navigate = useNavigate()  

  const handleClick = () => {
    navigate('give')
  }

  return (
    <div className="Home columns">
      <div className="center-column column">
        <div className="row"><h1>Hi {invite.respondent_name.split(' ')[0]},</h1></div>
        <div className="row"><Photo data={invite.user_photo} alt={invite.user_fullname} size="large" /></div>
        <div className="row">{invite.user_fullname} has asked for your feedback</div>
        <div className="row"><Button className="button large-button" value="Give Feedback" onClick={handleClick}/></div>
      </div>
    </div>
  );
}