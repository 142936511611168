import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useGlobalState } from '../hooks'

export const PrivateRoute = ({ element: Component, roles}) => {

  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')

  let location = useLocation()

  if (!isLoggedIn) {
    // not logged in so redirect to login page with the return url
    return <Navigate to='/login' state={{from: location}} replace={true} />
  }

  // authorised so return component
  return <Component replace={true}/>

}