import { subjects } from './'

export const handleResponse = async (response) => {

    if (response) { // if a fetch call is aborted, response will be empty 
        try { 

            const res = await response.text()

            var error = ""

            if (!response.ok) {

                if ([400, 500].indexOf(response.status) !== -1) {

                    try {
                        error = JSON.parse(res)
                    }
                    catch(e) {
                        error = res
                    }
                    
                }
                else if ([401, 403].indexOf(response.status) !== -1) {
                    subjects.isLoggedIn.next(false)
                    subjects.invite.next({})

                    error = JSON.parse(res)
                    error = { message: error.message, error: error.error }

                }
                else if ([404].indexOf(response.status) !== -1) {
                    error = { message: "Requested service not found", error: response.url  }
                }
                else if (!response) {
                    error = { message: "Unknown error", error: JSON.parse(res) }
                }

                return Promise.reject(error)
            }

            try {
                const data = JSON.parse(res)

                return data;
            } catch(e) {

                return res
            }
       
        } catch (e) {
            if (e.name === "AbortError") { // this should not be necessary here, some fetch aborts are leaked from customFetch
                return
            }

            return Promise.reject(e)

        }

    } else {
        return Promise.resolve()
    }
}