import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Input, FullscreenMessage } from '../components'
import { useGlobalState, useMessage } from '../hooks'

export const Login = ({login}, props) => {

  const initialFormState = {
    invite_token: ''
  }

  const [formData, setFormData] = useState(initialFormState)
  const [invite, setInvite] = useGlobalState('invite')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')
  const [loading, setLoading] = useState(false)

  const { message, showMessage, hideMessage } = useMessage()

  const navigate = useNavigate()
  const location = useLocation()

  const { token } = useParams()

  useEffect(() => {
    // Redirect to start page if user is already logged in
    if (isLoggedIn && invite) { 
      //navigate('/')
    }    

    if (token) {
      setFormData({ ...formData, invite_token: token })
    }
  }, [token]);

  const handleInputChange = (event) => {
    const { value, name } = event.target
  
    hideMessage()
  
    setFormData({ ...formData, [name]: value })
  }

  const requestLogin = async (inviteToken) => {
    try {

      setLoading(true)
      const state = location.state || { from: { pathname: "/" } };

      await login({invite_token: inviteToken, state})

      setLoading(false)

    } catch (error) {

      setLoading(false)
      showMessage(error, 'error', true)
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()

    const { invite_token }  = formData

    requestLogin(invite_token)

  }


  return (
    <div className="Login fullscreen">
      <div className="fullscreen-content">
        
        <div className="fullscreen-logo"></div>
        
        <form onSubmit={onSubmit}>
          <Input
            type="text"
            name="invite_token"
            className="large"
            label="Enter Invitation Code"
            labelAsPlaceholder={true}
            value={formData.invite_token}
            onChange={handleInputChange}
            disabled={loading}
            required={true}
          />
          <p>&nbsp;</p>
          <button 
            type="submit" 
            className={"button large-button" + (loading ? ' loading' : '')} 
            disabled={loading}>
            <span>{loading ? 'Signing In...' : 'Sign In'}</span>
          </button>
        </form>

        <FullscreenMessage message={message} />

      </div>
    </div>
  )
}